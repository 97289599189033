module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://45a1a5cebb5248e2b1ba0a9dc13e44f3@o397970.ingest.sentry.io/5253043","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/@mobile-reality/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleConsent":{"adStorage":"gatsby-gdpr-google-ad-storage","analyticsStorage":"gatsby-gdpr-google-analytics-storage","functionalityStorage":"gatsby-gdpr-google-functionality-storage","personalizationStorage":"gatsby-gdpr-google-personalization-storage","adUserData":"gatsby-gdpr-google-ad-user-data","adPersonalization":"gatsby-gdpr-google-ad-personalization","waitForUpdate":500},"googleTagManager":{"trackingIds":["GTM-MRLLX2V"],"dataLayerName":"dataLayer"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
